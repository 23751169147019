<template>
  <div class="list mainform">
    <div class="mainHeader">新建档案<span @click="back" class="back">返回></span></div>
    <div class="evaluation-box">
      <el-form :model="fileFormData" :rules="rules" ref="fileFormData" label-width="120px" class="demo-ruleForm clearfix">
        <!-- <el-form-item label="档案编号">
          <el-input v-model="fileFormData.number"></el-input>
        </el-form-item> -->
        <el-form-item label="档案描述" prop="message">
          <el-input v-model="fileFormData.message"></el-input>
        </el-form-item>
        <el-form-item label="考评季度" prop="quarter">
          <el-input v-model="fileFormData.quarter"></el-input>
        </el-form-item>
        <el-form-item label="考评模板" prop="templateName">
          <el-select v-model="fileFormData.templateName" placeholder="请选择模板">
            <el-option label="标准考评模板-单一类" value="STANDARD_TEMPLATE01--标准考评模板-单一类"></el-option>
            <el-option label="标准考评模板-非单一类" value="STANDARD_TEMPLATE02--标准考评模板-非单一类"></el-option>
            <el-option label="标准考评模板-类单一类" value="STANDARD_TEMPLATE03--标准考评模板-类单一类"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考评日期从" prop="startDate">
          <el-date-picker v-model="fileFormData.startDate" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="考评日期至" prop="endDate">
          <el-date-picker v-model="fileFormData.endDate" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="开放系统计算值">
          <el-checkbox v-model="fileFormData.isEffective"></el-checkbox>
        </el-form-item>
        <el-form-item class="textExplain" label="考评说明">
          <el-input type="textarea" v-model="fileFormData.strExplain" placeholder="请输入参评说明"></el-input>
        </el-form-item>
        <el-form-item class="subBtn">
          <el-button size="small" type="primary" @click="submitForm('fileFormData')">保存</el-button>
          <el-button size="small" @click="resetForm('fileFormData')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'AddFiled',
  data () {
    return {
      fileFormData: {
        message: '',
        quarter: '',
        templateName: '',
        startDate: '',
        endDate: ''
      },
      rules: {
        message: [
          { required: true, message: '请输入档案描述', trigger: 'blur' }
        ],
        quarter: [
          { required: true, message: '请输入考评季度', trigger: 'blur' }
        ],
        templateName: [
          { required: true, message: '请选择考评模板', trigger: 'change' }
        ],
        startDate: [
          { type: 'date', required: true, message: '请选择考评开始日期', trigger: 'change' }
        ],
        endDate: [
          { type: 'date', required: true, message: '请选择考评结束日期', trigger: 'change' }
        ]
      }
    }
  },
  created () {
  },

  methods: {
    // 保存
    submitForm (formName) {
      let cjksTime = ''
      let cjjsTime = ''
      cjksTime = dayjs(this.fileFormData.startDate).format('YYYY-MM-DD')
      cjjsTime = dayjs(this.fileFormData.endDate).format('YYYY-MM-DD')
      this.fileFormData.startDate = cjksTime
      this.fileFormData.endDate = cjjsTime
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const obj = {
            ...this.fileFormData,
            companyName: '中科曙光'
          }
          console.log('obj', obj)
          request('/api/evaluation/evaluation/save', 'POST', obj).then((res) => {
            if (res.code === '200') {
              this.$message({
                showClose: true,
                type: 'success',
                message: '操作成功'
              })
              this.$router.push({
                path: '/MainData/FileManage'
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },

    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/elForm.scss';
  @import '@/assets/css/form.scss';
  @import '@/assets/css/tableList.scss';
.list{
  .exportData{
    border-bottom: 1px solid #dddcdc;
    padding: 8px 0px 5px;
  }
  .textBtn{
    font-size: 16px;
  }
  .tableList{
    margin-top: 0;
    padding: 0;
  }

  .evaluation-box{
    max-width: 1200px;
    margin: 30px auto 0;
    padding: 0 60px;
    .el-form-item{
      width: 46%;
      float: left;
      margin-bottom: 18px;
    }
    .el-select{
      width: 100%;
    }
    :deep(.el-input--prefix){
      width: 100%;
    }
    :deep(.el-input__inner){
      height: 34px;
      line-height: 34px;
    }
    .textExplain{
      width: 100%;
      .el-textarea{
        width: 91%;
      }
    }
    .subBtn{
      :deep(.el-form-item__content){
        margin-left: 0 !important;
      }
      width: 100%;
      text-align: center;
    }
  }
  .elrowflx{
    margin-bottom: 20px;
    :deep(.el-col){
      margin-bottom: 8px;
      line-height: 34px;
    }
  }
  .xjtit{
    float: left;
    margin-right: 20px;
    min-width: 98px;
    text-align: right;
    color: #666;
    font-size: 14px;
  }
  .xjcont{
    float: left;
    width: 50%;
    color: #666;
    font-size: 14px;
    line-height: 20px;
    margin-top: 7px;
    margin-bottom: 5px;
    .el-form-item{
      margin-bottom: 0;
    }
  }
  .xjcontMs{
    margin-top: -3px;
    margin-bottom: 0;
    :deep(.el-input__inner){
      line-height: 32px;
      height: 32px;
    }
    .el-select{
      margin-top: 5px;
    }
  }
  .xjcontCpsm{
    width: calc(100% - 222px);
  }
  :deep(.el-collapse-item__header){
    font-size: 16px;
    color: #666;
  }
  :deep(.el-collapse-item__content){
    padding-bottom: 0;
  }
  .el-collapse{
    border-top: 0;
  }
}
</style>
